import { useAuthStore } from "@/store/auth"
import { PublicConnectorDetails } from "@/store/newPublicConnector"
import { AnalyticsResponseType, TransactionsAnalyticsResponseData } from "@/types/Analytics"
import { ConfigType, ConnectorType, DataConnectorsType } from "@/types/DataConnectorsType"
import { ResponseType } from "@/types/LoginResponseType"
import { StatisticType } from "@/types/StatisticType"
import { TemplatesType } from "@/types/TemplatesType"
import { UserType } from "@/types/UserType"
import { notification } from "antd"
import ky, { NormalizedOptions } from "ky"

const API_URL = `${import.meta.env.WEBSITE_API_BASE_URL ??  "http://localhost:3000"}/api/`
//"https://datacord.bndigital.dev"}/api/`

const errorNotification = async (_request: Request, _options: NormalizedOptions, response: Response) => {
  if (!response.ok) {
    const errorMessage = await response.text()
    notification.error({ placement: "topRight", message: `Error: ${errorMessage}'` })
  }
}

const api = ky.create({
  prefixUrl: API_URL,
  timeout: 30000,
  hooks: {
    beforeRequest: [
      async request => {
        const token = localStorage.getItem("token")!
        if (token) {
          request.headers.set("Content-Type", "application/json")
          request.headers.set("Authorization", `Bearer ${token}`)
        }
      },
    ],
    afterResponse: [
      errorNotification,
      async (request, options, response) => {
        if (response.status === 401) {
          await useAuthStore.getState().refresh()

          const newToken = localStorage.getItem("token")!
          if (newToken) {
            request.headers.set("Content-Type", "application/json")
            request.headers.set("Authorization", `Bearer ${newToken}`)
            return ky(request)
          }
        }
        return response
      },
    ],
  },
})

const AUTH_PATH = "auth"
const ME_PATH = "users/me"
const ALL_USERS_PATH = "users"
const CONFIRM_USER = "users/confirm"

const REGISTRATION_PATH = "registration"
const REFRESH_PATH = "refresh"
const RESET_PASSWORD_PATH = "users/reset-password"
const UPDATE_PASSWORD_PATH = "users/update-password"

const ALL_CONNECTORS_PATH = "connectors"
const CREATE_CONNECTOR_PATH = "connectors/"
const UPDATE_CONNECTOR_PATH = "connectors/"
const DELETE_CONNECTOR = "connectors/"
const VALIDATE_CONFIG = "connectors/validateConfig"
const VALIDATE_DATA_MAPPING = "connectors/validateDataMapping"
const PAUSE_CONNECTOR = "connectors/pause"
const RESUME_CONNECTOR = "connectors/resume"

const ALL_CONFIGS_PATH = "configs"

const GET_DASHBOARD_ANALYTICS = "analytics/dashboard"
const GET_STATISTIC = "analytics/statistic"
const GET_TRANSACTIONS = "analytics/transactions"

const GET_ALL_PUBLIC_CONNECTORS = "templates"
const CREATE_PUBLIC_CONNECTOR = "templates"
const UPDATE_PUBLIC_CONNECTOR = "templates/"
const DELETE_PUBLIC_CONNECTOR = "templates/"

export async function checkAuth(token: string | null) {
  try {
    return api.get(ME_PATH, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
    })
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Auth check failed: ${error.message}` })
    throw error
  }
}

export async function confirmUser(token: string): Promise<ResponseType> {
  try {
    return  ky(`${API_URL}${CONFIRM_USER}`, {
      method: "POST",
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
      //hooks: { afterResponse: [errorNotification] },
    }).json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `User confirmation failed: ${error.message}` })
    throw error
  }
}

export async function login(email: string, password: string): Promise<ResponseType> {
  try {
    return  ky(`${API_URL}${AUTH_PATH}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
      //hooks: { afterResponse: [errorNotification] },
    }).json()
  } catch (error) {
    if (error instanceof Error) notification.error({ placement: "topRight", message: `Login failed: ${error.message}` })
    throw error
  }
}

export async function registration(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  workspaceName?: string,
  companyName?: string
) {
  try {
    return  ky(`${API_URL}${REGISTRATION_PATH}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, firstName, lastName, workspaceName, companyName }),
      //hooks: { afterResponse: [errorNotification] },
    }).json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Registration failed: ${error.message}` })
    throw error
  }
}

export async function handleRefreshToken(refreshToken: string) {
  try {
    return  api
      .post(REFRESH_PATH, {
        headers: {
          "Content-Type": "application/json",
        },
        json: { refreshToken },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Refresh token failed: ${error.message}` })
    throw error
  }
}

export async function resetPassword(email: string) {
  try {
    return  ky(`${API_URL}${RESET_PASSWORD_PATH}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
      //hooks: { afterResponse: [errorNotification] },
    }).json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Password reset failed: ${error.message}` })
    throw error
  }
}

export async function updatePassword(password: string, token: string) {
  try {
    return  ky(`${API_URL}${UPDATE_PASSWORD_PATH}`, {
      method: "POST",
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password: { new: password } }),
      //hooks: { afterResponse: [errorNotification] },
    }).json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Password update failed: ${error.message}` })
    throw error
  }
}

export async function getAllConnectors(token: string): Promise<DataConnectorsType[]> {
  try {
    return  api
      .get(ALL_CONNECTORS_PATH, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get all connectors failed: ${error.message}` })
    throw error
  }
}

export async function getConnector(id: string, token: string): Promise<DataConnectorsType> {
  try {
    return  api
      .get(`${ALL_CONNECTORS_PATH}/${id}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get connector by id failed: ${error.message}` })
    throw error
  }
}

export async function createConnector(body: any, token: string): Promise<DataConnectorsType> {
  try {
    return  api
      .post(CREATE_CONNECTOR_PATH, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: body,
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Create connector failed: ${error.message}` })
    throw error
  }
}

export async function updateConnector(
  body: Partial<ConnectorType>,
  id: string,
  token: string
): Promise<DataConnectorsType> {
  try {
    return  api
      .patch(`${UPDATE_CONNECTOR_PATH}${id}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: body,
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Update connector failed: ${error.message}` })
    throw error
  }
}

export async function validateConfig(body: any, token: string) {
  try {
    return  api
      .post(VALIDATE_CONFIG, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: body,
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Validate config failed: ${error.message}` })
    throw error
  }
}

export async function validateDataMapping(body: any, token: string) {
  try {
    return  api
      .post(VALIDATE_DATA_MAPPING, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: { dataMapping: body },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Validate data mapping failed: ${error.message}` })
    throw error
  }
}

export async function getAllUsers(): Promise<UserType[]> {
  try {
    return  api.get(ALL_USERS_PATH).json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get all users failed: ${error.message}` })
    throw error
  }
}

export async function removeConnector(id: string, token: string): Promise<ConnectorType> {
  try {
    return  api
      .delete(`${DELETE_CONNECTOR}${id}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Remove connector failed: ${error.message}` })
    throw error
  }
}

export async function getConfigById(id: string, token: string): Promise<ConfigType> {
  try {
    return  api
      .get(`${ALL_CONFIGS_PATH}/${id}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get config by id failed: ${error.message}` })
    throw error
  }
}

export async function pauseConnector(id: string, token: string): Promise<ConfigType> {
  try {
    return  api
      .post(`${PAUSE_CONNECTOR}/${id}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Pause connector failed: ${error.message}` })
    throw error
  }
}

export async function resumeConnector(id: string, token: string): Promise<ConfigType> {
  return api
    .post(`${RESUME_CONNECTOR}/${id}`, {
      headers: {
        "Authorization": token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
    })
    .json()
}

export async function getDashboardAnalytics(
  connectorId: string,
  startDate: string,
  endDate: string,
  interval: string,
  token: string,
  status?: string[]
): Promise<AnalyticsResponseType> {
  try {
    return  api
      .post(`${GET_DASHBOARD_ANALYTICS}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: { connectorId, startDate, endDate, interval, statusCodes: status },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get dashboard analytics failed: ${error.message}` })
    throw error
  }
}

export async function getStatistic(
  connectorId: string,
  startDate: string,
  endDate: string,
  token: string
): Promise<StatisticType> {
  try {
    return  api
      .post(`${GET_STATISTIC}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: { connectorId, startDate, endDate },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get statistic failed: ${error.message}` })
    throw error
  }
}

export async function getTransactionsAnalytics(
  connectorId: string,
  startDate: string,
  endDate: string,
  token: string
): Promise<TransactionsAnalyticsResponseData> {
  try {
    return  api
      .post(`${GET_TRANSACTIONS}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: { connectorId, startDate, endDate },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get transactions analytics failed: ${error.message}` })
    throw error
  }
}

export async function getAllPublicConnectors(token: string): Promise<TemplatesType[]> {
  try {
    return  api
      .get(GET_ALL_PUBLIC_CONNECTORS, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get all public connectors failed: ${error.message}` })
    throw error
  }
}

export async function getPublicConnectorById(id: string, token: string): Promise<PublicConnectorDetails[]> {
  try {
    return  api
      .get(`${GET_ALL_PUBLIC_CONNECTORS}/${id}`, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Get public connector by id failed: ${error.message}` })
    throw error
  }
}

export async function createPublicConnectors(
  body: PublicConnectorDetails,
  token: string
): Promise<PublicConnectorDetails[]> {
  try {
    return  api
      .post(CREATE_PUBLIC_CONNECTOR, {
        headers: {
          "Authorization": token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
        },
        json: body,
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Create public connector failed: ${error.message}` })
    throw error
  }
}

export async function updatePublicConnector(id: string, body: any, token: string): Promise<DataConnectorsType> {
  try {
    return  api
      .put(`${UPDATE_PUBLIC_CONNECTOR}${id}`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
        json: body,
      })
      .json()
  } catch (error) {
    if (error instanceof Error)
      notification.error({ placement: "topRight", message: `Update public connector failed: ${error.message}` })
    throw error
  }
}
