import {checkAuth, getAllConnectors} from "@/shared/api";
import {useAuthStore} from "@/store/auth";
import {useConnectorsStore} from "@/store/connectors";
import {DataConnectorsType} from "@/types/DataConnectorsType";
import {getAuthToken} from "@/utils/auth";
import {Flex, Layout, Spin} from "antd";
import {FC, useEffect, useLayoutEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {useTernaryDarkMode} from "usehooks-ts";

const DashboardLayout: FC = () => {
	const { loggedIn, set } = useAuthStore(store => ({
		loggedIn: store.loggedIn,
		set: store.set,
	}))
	const setConnectors = useConnectorsStore(state => state.setConnectors)
	const { isDarkMode } = useTernaryDarkMode({ localStorageKey: "theme" })
	const [loading, setLoading] = useState(true);

	const resetUserState = () => {
		set(store => {
			store.loggedIn = false
			store.user = undefined
		})
	}

	useEffect(() => {
		const getMe = async () => {
			const token = getAuthToken()

			if (!token) {
				console.warn("No auth token found. Resetting user state.")
				resetUserState()
				return
			}

			try {

				const [authResponse, connectorsResponse]: [any, DataConnectorsType[]] = await Promise.all([
					checkAuth(token),
					getAllConnectors(token),
				])

				const authJson: any = await authResponse.json()

				set(store => {
					store.loggedIn = !!authJson
					store.user = authJson.user ?? authJson
				})
				setConnectors(connectorsResponse || [])
			} catch (error) {
				console.error("Error during authentication check:", error)
				resetUserState()
			} finally {
				setLoading(false)
			}
		}

		getMe()
	}, []);

	if (loading) return <Flex justify={'center'} flex={1} align={'center'} style={{
		height: '100vh'
	}}>
		<Spin size={"large"}/>
	</Flex>

	return (
		<Layout className={isDarkMode ? "dark" : "light"}>
			<Outlet/>
		</Layout>
	)
}

export default DashboardLayout