import { pauseConnector, removeConnector, resumeConnector, updateConnector } from "@/shared/api"
import { ConnectorType, DataConnectorsType } from "@/types/DataConnectorsType"
import { getAuthToken } from "@/utils/auth"
import { produce } from "immer"
import { create } from "zustand"
import { errors, logs } from "../../shared_lib/logger"

type ConnectorsState = {
  connectors: DataConnectorsType[]
}

type ConnectorsActions = {
  setConnectors: (connectors: DataConnectorsType[]) => void
  deleteConnector: (id: string) => void
  updateStoreConnector: (id: string, updatedData: any) => void
  updateConnectorById: (newData: any, id: string) => void
  addStoreConnector: (newData: DataConnectorsType) => void
  pauseConnectorById: (id: string) => void
  resumeConnectorById: (id: string) => void
}

export const useConnectorsStore = create<ConnectorsState & ConnectorsActions>(setStore => ({
  setConnectors: connectors => {
    setStore(
      produce(state => {
        state.connectors = connectors
      })
    )
  },
  connectors: [],
  updateStoreConnector: (id: string, updatedData: Partial<DataConnectorsType>) => {
    setStore(
      produce(state => {
        const index = state.connectors.findIndex((it: DataConnectorsType) => it.connector?._id === id)
        if (index !== -1) {
          state.connectors[index] = {
            ...state.connectors[index],
            connector: { ...state.connectors[index].connector, ...updatedData.connector },
            config: { ...state.connectors[index].config, ...updatedData.config },
          }
        }
      })
    )
  },
  addStoreConnector: (newData: DataConnectorsType) => {
    setStore(
      produce(state => {
        state.connectors.push(newData)
      })
    )
  },
  deleteConnector: async (id: string) => {
    try {
      setStore(
        produce(state => {
          state.connectors = state.connectors.filter((it: DataConnectorsType) => it.connector?._id !== id)
        })
      )
      const response = await removeConnector(id, getAuthToken()!)
      logs(response)
    } catch (error) {
      console.error("Error:", error)
    }
  },
  updateConnectorById: async (newData: Partial<ConnectorType>, id: string): Promise<DataConnectorsType | void> => {
    try {
      return await updateConnector(newData, id, getAuthToken()!)
    } catch (error) {
      console.error("Error:", error)
    }
  },
  pauseConnectorById: async (id: string): Promise<DataConnectorsType | void> => {
    try {
      await pauseConnector(id, getAuthToken()!)
      setStore(
        produce(state => {
          const index = state.connectors.findIndex((it: DataConnectorsType) => it.connector?._id === id)
          if (index !== -1) {
            state.connectors[index] = {
              ...state.connectors[index],
              connector: { ...state.connectors[index].connector, status: "Pause" },
            }
          }
        })
      )
    } catch (error) {
      console.error("Error:", error)
    }
  },
  resumeConnectorById: async (id: string): Promise<DataConnectorsType | void> => {
    try {
      await resumeConnector(id, getAuthToken()!)
      setStore(
        produce(state => {
          const index = state.connectors.findIndex((it: DataConnectorsType) => it.connector?._id === id)
          if (index !== -1) {
            state.connectors[index] = {
              ...state.connectors[index],
              connector: { ...state.connectors[index].connector, status: "Active" },
            }
          }
        })
      )
    } catch (error) {
      console.error("Error:", error)
    }
  },
}))
