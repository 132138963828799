import { handleRefreshToken, login } from "@/shared/api"
import { useConnectorsStore } from "@/store/connectors"
import { ResponseType } from "@/types/LoginResponseType"
import { UserType } from "@/types/UserType"
import { setAuthToken } from "@/utils/auth"
import { Draft, Immutable, produce } from "immer"
import { create } from "zustand"

type AuthState = {
  loggedIn: boolean | undefined
  user: UserType | undefined
}

type AuthActions = {
  set: (cb: (store: Draft<AuthState>) => void) => void
  login: (
    email: string,
    password: string,
    setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>
  logout: () => void
  refresh: () => Promise<void>
}

export type AuthStore = Immutable<AuthState & AuthActions>

export const useAuthStore = create<AuthStore>((setStore, getStore) => ({
  set: cb => setStore(produce<AuthStore>(cb)),

  loggedIn: !!localStorage.getItem('token'),
  user: undefined,

  login: async (email, password, setOpenModal) => {
    try {
      let response: ResponseType = await login(email, password)
      if (response && response.accessToken) {
        if (!response.user.confirmed) {
          setOpenModal && setOpenModal(true)
        } else {
          setAuthToken(response.accessToken)
          localStorage.setItem("accessTokenExpiresIn", response.accessTokenExpiresIn)
          localStorage.setItem("refreshToken", response.refreshToken)
          localStorage.setItem("refreshTokenExpiresIn", response.refreshTokenExpiresIn)

          setStore(
            produce((state: Draft<AuthState>) => {
              state.loggedIn = true
              state.user = response.user
            })
          )

          window.location.href = "/dashboard"
        }
      }
    } catch (error) {
      console.error("Error:", error)
    }
  },

  logout: () => {
    setAuthToken(null)
    localStorage.removeItem("accessTokenExpiresIn")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("refreshTokenExpiresIn")

    setStore(
      produce((state: Draft<AuthState>) => {
        state.loggedIn = false
        state.user = undefined
      })
    )

    window.location.href = "/sign-in"
  },

  refresh: async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken")
      if (!refreshToken) throw new Error("No refresh token found")

      const response: any = await handleRefreshToken(refreshToken)

      if (response?.accessToken) {
        setAuthToken(response.accessToken)
        localStorage.setItem("accessTokenExpiresIn", response.accessTokenExpiresIn)
        localStorage.setItem("refreshToken", response.refreshToken)
        localStorage.setItem("refreshTokenExpiresIn", response.refreshTokenExpiresIn)

        setStore(
          produce((state: Draft<AuthState>) => {
            state.loggedIn = true
            state.user = response.user
          })
        )
      } else {
        getStore().logout()
      }
    } catch (error) {
      console.error("Error refreshing token:", error)
      getStore().logout()
    }
  },
}))
